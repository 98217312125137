<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  created(){
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body, html{
    height: 100%;
    font-family: 'Poppins', sans-serif !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(245, 66, 135, 0.5); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(245, 66, 135, 1)
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(245, 66, 135, 0.4)
}
</style>