import { vuexfireMutations, firestoreAction } from 'vuexfire'
import firebase from './plugins/firebase'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const db = firebase.firestore();

const store = new Vuex.Store({
  state: {
    todos: [], // Will be bound as an array
    
    user: null, 
    
    productRecords: [], //menyesuaikan usernya
    events: [],
    packages: [],
    products: [],

    srid: "analitica",
    evid: "",
  },
  getters:{
    
  },
  setters:{
    setEvid(state, evid){
      state.evid = evid;
    },
  },
  mutations: {
    user: (state, newUser) => {
      state.user = newUser;
    },
    productRecords: (state, newValue) => {
      state.productRecords = newValue;
    },

    ...vuexfireMutations,
  },
  actions:{
    //Events
    bindEvents: firestoreAction(({ bindFirestoreRef, state }) => {
        return bindFirestoreRef(
          'events',
          db.collection(`/sources/${state.srid}/events`).where('show','==',true)
        ).catch(error => {
          alert(error.code + " : " + error.message);
          console.log(error)
        })
      }),
    
    //Packages
    bindPackages: firestoreAction(({ bindFirestoreRef, state }) => {
      return bindFirestoreRef(
        'packages',
        db.collection(`/sources/${state.srid}/packages`).where('show','==',true)
      ).catch(error => {
        alert(error.code + " : " + error.message);
        console.log(error)
      })
    }),

    //Products
    bindProducts: firestoreAction(({ bindFirestoreRef, state }) => {
      return bindFirestoreRef(
        'products',
        db.collection(`/sources/${state.srid}/products`).where('show','==',true)
      ).catch(error => {
        alert(error.code + " : " + error.message);
        console.log(error)
      })
    }),

    //ProductRecords
    bindProductRecords: firestoreAction(({ bindFirestoreRef, state }) => {
      return bindFirestoreRef(
        'productRecords',
        db.collection(`/users/${state.user.uid}/sources/analitica/productRecords`)
      ).catch(error => {
        alert(error.code + " : " + error.message);
        console.log(error)
      })
    }),
    
  }
})

export default store;