import Vue from 'vue'
import Router from 'vue-router'
import '@babel/polyfill'
//import store from '../store.js';


//const Page404 = () => import('@/views/pages/Page404')
//const Pay = () => import('@/views/Pay')
// import PayCoreAPIv2 from '../views/PayCoreAPIv2.vue'
import PaySnap from '../views/PaySnap.vue'
// import TestingPaymentChannel from '../views/TestingPaymentChannel.vue'
//import HandleNotification from '../views/HandleNotification.vue'
// import Response from '../views/Response.vue'
// import Home from '../views/Home.vue'
// import Event from '../views/Event.vue'
// import layout from '../views/Layout_Test.vue'
// import Kinara from '../views/Kinara.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history', 
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
      // {
      //   path: '/',
      //   name: 'Home',
      //   component: Home
      // },
      // {
      //   path: '/:evid',
      //   name: 'Event',
      //   component: Event
      // },
      {
        path: '/:orderid/:state',
        name: 'PaySnap',
        component: PaySnap
      },
      // {
      //   path: '/test-payment/:orderid/:state',
      //   name: 'TestingPaymentChannel',
      //   component: TestingPaymentChannel
      // },
      // {
      //   path: '/core-api/:orderid/:state',
      //   name: 'PayCoreAPI',
      //   component: PayCoreAPIv2
      // },
      // {
      //   path: '/response/:message',
      //   name: 'Response',
      //   component: Response
      // },
      // {
      //   path: '/Layout',
      //   name: 'Layout_Test',
      //   component: layout
      // },
      // {
      //   path: '/Kinara',
      //   name: 'Testing',
      //   component: Kinara
      // }

  ]
}


export default router;