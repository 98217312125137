<template>
  <div>
    <b-navbar class="p-3" style="border-bottom:solid 1px rgb(200,200,200)" variant="faded" type="light">
        <center style="width:100%">
            <b-navbar-brand>
                <img src="../assets/logo-analitica-black.png" alt="Analitica Logo" style="max-height:40px">
            </b-navbar-brand>
        </center>
    </b-navbar>
    <b-container v-if="!loading">
        <b-row class="mb-2 py-5" align-v="center">
            <b-col md="8" offset-md="2">
                <b-row>
                    <b-col cols="4" class="text-center">
                        <center>
                            <div    
                                class="mb-2"
                                :class="{'active-number':activeNumber==1, 'number':activeNumber!==1}"
                                >
                                1
                            </div>
                            <div class="step-description">Checkout<br>Pembayaran</div>
                        </center>
                    </b-col>
                    <b-col cols="4" class="text-center">
                        <center>
                            <div    
                                class="mb-2"
                                :class="{'active-number':activeNumber==2, 'number':activeNumber!==2}"
                                >
                                2
                            </div>
                            <div class="step-description">Menunggu <br> Pembayaran</div>
                        </center>
                    </b-col>
                    <b-col cols="4" class="text-center">
                        <center>
                            <div    
                                class="mb-2"
                                :class="{'active-number':activeNumber==3, 'number':activeNumber!==3}"
                                >
                                3
                            </div>
                            <div class="step-description">Transaksi <br> Selesai</div>
                        </center>
                    </b-col>
                    <hr class="mt-4" style="width: 100%; border: 1px solid #EC008C;">
                </b-row>
            </b-col>
        </b-row>
    </b-container>
    <b-container v-if="loading" style="min-height: 80vh !important">
        <b-row align-v="center" style="min-height: 80vh">
            <b-col align-self="center" class="text-center">
                <b-spinner 
                    style="width: 3rem; height: 3rem;" 
                    label="Large Spinner"
                    v-if="loading"
                >
                </b-spinner>
                <p 
                    class="mt-2"
                    :class="{ 'danger':isError }"
                >
                    <b>
                        {{ loadingMessage }}
                    </b>
                </p>
            </b-col>
        </b-row>
    </b-container>
    <b-container v-else-if="status == 'new' && !loading">
        <b-row>
            <b-col md="8" offset-md="2" class="mb-3">
                <!-- Detail Pesanan -->
                <h4>Detail Pesanan</h4>
                <b-card
                    header-tag="header" 
                    class="payment-card mb-4"
                >
                    <template #header>
                        <h5 class="mb-0 header-text">{{ item.name }}</h5>
                    </template>
                    <b-card-text>
                        Harga Produk
                        <p v-if="item.discount || item.discount !== 0" class="price mt-2">
                            <b class="cutted-price">{{ formatPrice(item.price) }}</b>
                            {{ formatPrice(item.price - item.discount) }}
                        </p>
                        <p v-else class="price mt-2">
                            {{ formatPrice(item.price) }}
                        </p>
                    </b-card-text>
                </b-card>

                <!-- Detail Pesanan -->
                <h4>Metode Pembayaran</h4>
                <b-card
                    header-tag="header" 
                    class="payment-card"
                >
                    <template #header>
                        <h6 class="mb-0 header-text">Pilih metode pembayaran yang diinginkan</h6>
                    </template>
                    <b-card-text class="accordion">
                        <!-- Bank Transfer -->
                        <div
                            v-b-toggle.bank-transfer
                            @click="activePayment = (activePayment=='bankTransfer') ? null : 'bankTransfer';"
                            class="header-metode mb-3"
                            >
                            Akun Virtual (Virtual Account)
                            <b-icon 
                                v-if="activePayment!=='bankTransfer'" 
                                class="float-right" 
                                icon="caret-down-fill">
                            </b-icon>
                            <b-icon v-else class="float-right active-icon" icon="caret-up-fill"></b-icon>
                        </div>
                        <b-collapse id="bank-transfer" accordion="my-accordion" class="mt-2">
                            <!--<b-card>-->
                                <b-card class="my-2 card-metode" @click="metode = 'bni'">
                                    <b-row>
                                        <div class="col-10">
                                            <img style="max-width: 100px" src="../assets/icon/BNI.png">
                                        </div>
                                        <div class="col-2">
                                            <b-form-radio 
                                                v-model="metode" 
                                                size="lg" 
                                                value="bni"
                                                :checked="metode == 'bni' ? true:false"
                                                >
                                            </b-form-radio>
                                        </div>
                                    </b-row>    
                                </b-card>
                                <b-card class="my-2 card-metode" @click="metode = 'permata'">
                                    <b-row>
                                        <div class="col-10">
                                            <img style="max-width: 100px" src="../assets/icon/Bank Permata.png">
                                        </div>
                                        <div class="col-2">
                                            <b-form-radio 
                                                v-model="metode" 
                                                size="lg" 
                                                value="permata"
                                                :checked="metode == 'permata' ? true:false"
                                                >
                                            </b-form-radio>
                                        </div>
                                    </b-row>    
                                </b-card>
                                <b-card class="my-2 card-metode" @click="metode = 'mandiribill'">
                                    <b-row>
                                        <div class="col-10">
                                            <img style="max-width: 100px" src="../assets/icon/Mandiri Bill.png">
                                        </div>
                                        <div class="col-2">
                                            <b-form-radio 
                                                v-model="metode" 
                                                size="lg" 
                                                value="mandiribill"
                                                :checked="metode == 'mandiribill' ? true:false"
                                                >
                                            </b-form-radio>
                                        </div>
                                    </b-row>    
                                </b-card>
                                <!--<button 
                                    :class="{ 'active-button':(bank=='bni'&&paymentType=='bank_transfer') }"
                                    class="passive-button"
                                    @click="pilihMetode('bni')"
                                    >
                                    BNI VA
                                </button>
                                <button 
                                    :class="{ 'active-button':paymentType=='permata' }"
                                    class="passive-button"
                                    @click="pilihMetode('permata')"
                                    >
                                    Permata VA
                                </button>
                                <button 
                                    :class="{ 'active-button':paymentType=='echannel' }"
                                    class="passive-button"
                                    @click="pilihMetode('mandiribill')"
                                    >
                                    Mandiri Bill
                                </button>
                            </b-card>-->
                        </b-collapse>

                        <!-- E-Money -->
                        <div
                            v-b-toggle.e-money
                            @click="activePayment = (activePayment=='eMoney') ? null : 'eMoney'"
                            class="header-metode mb-3"
                            >
                                Uang Elektronik (E-Money)
                            <b-icon 
                                v-if="activePayment!=='eMoney'" 
                                class="float-right" 
                                icon="caret-down-fill">
                            </b-icon>
                            <b-icon v-else class="float-right active-icon" icon="caret-up-fill"></b-icon>
                        </div>
                        <b-collapse visible id="e-money" accordion="my-accordion" class="mt-2">
                            <!--<b-card>-->
                                <b-card class="my-2 card-metode" @click="metode = 'gopay'">
                                    <b-row>
                                        <div class="col-10">
                                            <img style="max-width: 100px" src="../assets/icon/GoPay.png">
                                        </div>
                                        <div class="col-2">
                                            <b-form-radio 
                                                v-model="metode" 
                                                size="lg" 
                                                value="gopay"
                                                :checked="metode == 'gopay' ? true:false"
                                                >
                                            </b-form-radio>
                                        </div>
                                    </b-row>    
                                </b-card>
                                <!--<button 
                                    :class="{ 'active-button':paymentType=='gopay' }"
                                    class="passive-button"
                                    @click="pilihMetode('gopay')"
                                    >
                                    GoPay
                                </button>
                            </b-card>-->
                        </b-collapse>

                        <!-- Transaksi Manual -->
                        <div
                            v-b-toggle.manual
                            @click="activePayment = (activePayment=='manual') ? null : 'manual'"
                            class="header-metode mb-3"
                            >
                                Transfer Manual
                            <b-icon 
                                v-if="activePayment!=='manual'" 
                                class="float-right" 
                                icon="caret-down-fill">
                            </b-icon>
                            <b-icon v-else class="float-right active-icon" icon="caret-up-fill"></b-icon>
                        </div>
                        <b-collapse id="manual" accordion="my-accordion" class="mt-2">
                            <!--<b-card>-->
                                Dengan Transfer Manual, kamu bisa membayar dengan metode pembayaran lain 
                                yang tidak tertera di atas. Cara ini memiliki estimasi verifikasi 
                                selama paling lambat <b>2x24 jam</b> setelah transaksi dibuat. <br>

                                <div class="row">
                                    <div class="col-md-2 col-3 p-3" v-for="i in logoManual" :key="i">
                                        <img :src="require('../assets/icon/'+i+'.png')" style="max-width:100%">
                                    </div>
                                </div>
                                

                                <b-card class="my-2 card-metode" @click="metode = 'gform'">
                                    <b-row>
                                        <div class="col-10">
                                            <b>Transfer Manual</b>
                                        </div>
                                        <div class="col-2">
                                            <b-form-radio 
                                                v-model="metode" 
                                                size="lg" 
                                                value="gform"
                                                :checked="metode == 'gform' ? true:false"
                                                >
                                            </b-form-radio>
                                        </div>
                                    </b-row>    
                                </b-card>

                                <!--<button 
                                    :class="{ 'active-button':paymentType=='gform' }"
                                    class="passive-button mt-2"
                                    @click="pilihMetode('gform')"
                                    >
                                    Transfer Manual
                                </button>
                            </b-card>-->
                        </b-collapse>
                    </b-card-text>
                </b-card>
                
            </b-col>
            <b-col md="8" offset-md="2" class="h-100">
                <!-- Voucher -->
                <h4>Voucher</h4>
                <b-card
                    header-tag="header" 
                    class="payment-card mb-4"
                >
                    <template #header>
                        <h6 class="mb-0 header-text">Masukkan kode voucher untuk mendapatkan potongan harga</h6>
                    </template>
                    <b-card-text>
                        <b-form-input
                            v-model="voucherCode"
                            class="voucher-input"
                            type="text"
                            :state="voucherIsValid"
                        >
                        </b-form-input>
                        <b-spinner
                            class="mt-3"
                            v-if="voucherLoading"
                        >
                        </b-spinner>
                        <b-form-invalid-feedback :state="voucherIsValid">
                            Voucher tidak ditemukan.
                        </b-form-invalid-feedback>
                        <b-form-valid-feedback :state="voucherIsValid">
                            Voucher ditemukan.
                        </b-form-valid-feedback>
                    </b-card-text>
                </b-card>

                <!-- Rincian Akhir -->
                <h4 class="stick-top">Rincian Akhir</h4>
                <b-card
                    header-tag="header" 
                    class="payment-card mb-4"
                >
                    <template #header>
                        <h5 class="mb-0 header-text">Detail Total Biaya</h5>
                    </template>
                    <b-card-text>
                        <b-row>
                            <b-col cols="6">
                                <p class="mt-2">Harga Produk ({{ item.name }})</p>
                            </b-col>
                            <b-col cols="6" class="text-right">
                                <p class="mt-2">
                                    {{ formatPrice(item.price) }}
                                </p>
                            </b-col>
                        </b-row>
                        <b-row v-if="item.discount || item.discount !== 0">
                            <b-col cols="6">
                                <p>Diskon</p>
                            </b-col>
                            <b-col cols="6" class="text-right">
                                <p>
                                    - {{ formatPrice(item.discount) }}
                                </p>
                            </b-col>
                        </b-row>
                        <p v-if="item.discount || item.discount !== 0" class="text-right" style="width:100%; border-bottom: 1px solid rgb(200,200,200)">
                            <b>+</b>
                        </p>
                        <b-row v-if="item.discount || item.discount !== 0">
                            <b-col cols="6">
                                <p>Sub Total</p>
                            </b-col>
                            <b-col cols="6" class="text-right">
                                <p>
                                    {{ formatPrice(item.price - item.discount) }}
                                </p>
                            </b-col>
                        </b-row>
                        <b-row v-if="voucher">
                            <b-col cols="6">
                                <p>{{ voucher.name }}</p>
                            </b-col>
                            <b-col cols="6" class="text-right">
                                <p>
                                    - {{ formatPrice(voucher.amount) }}
                                </p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <p v-if="thirdParty == 'Pilih Metode'">Biaya Admin ({{ thirdParty }})</p>
                                <p v-else>
                                    Biaya Admin
                                </p>
                                <img style="max-width: 100px" v-if="thirdParty !== 'Pilih Metode' && thirdParty !== 'Manual'" :src="require('../assets/icon/'+thirdParty+'.png')">
                                <p v-if="thirdParty == 'Manual'">
                                    <b>Transfer Manual</b>
                                </p>
                            </b-col>
                            <b-col cols="6" class="text-right">
                                <p v-if="thirdParty !== 'Manual'">
                                    {{ formatPrice(biayaAdmin) }}
                                </p>
                                <p v-else>
                                    Di luar Aplikasi
                                </p>
                            </b-col>
                        </b-row>
                        <p class="text-right" style="width:100%; border-bottom: 1px solid rgb(200,200,200)">
                            <b>+</b>
                        </p>
                        <b-row>
                            <b-col cols="6">
                                <h5>Total</h5>
                            </b-col>
                            <b-col cols="6" class="text-right">
                                <h5>
                                    {{ formatPrice(finalPrice) }}
                                </h5>
                            </b-col>
                        </b-row>

                    </b-card-text>
                    <button
                        @click="modal.modalKonfirmasi = true"
                        :class="{'submit-button':(paymentType!=='' && paymentType), 'disabled-button':(paymentType=='' || !paymentType || disableConfirm)}"
                        :disabled="paymentType=='' || !paymentType || disableConfirm"
                    >
                        Konfirmasi Transaksi
                    </button>

                    <!-- Modal Konfirmasi Transaksi -->
                    <b-modal 
                        centered 
                        v-model="modal.modalKonfirmasi" 
                        id="modal-konfirmasi"
                        >
                        <template #modal-header>
                            <b class="text-center w-100">Konfirmasi Akhir</b>
                        </template>
                        <div>
                            <b-row>
                                <b-col cols="6">
                                    <p class="mt-2">Harga Produk ({{ item.name }})</p>
                                </b-col>
                                <b-col cols="6" class="text-right">
                                    <p class="mt-2">
                                        {{ formatPrice(item.price) }}
                                    </p>
                                </b-col>
                            </b-row>
                            <b-row v-if="item.discount || item.discount !== 0">
                                <b-col cols="6">
                                    <p>Diskon</p>
                                </b-col>
                                <b-col cols="6" class="text-right">
                                    <p>
                                        - {{ formatPrice(item.discount) }}
                                    </p>
                                </b-col>
                            </b-row>
                            <p v-if="item.discount || item.discount !== 0" class="text-right" style="width:100%; border-bottom: 1px solid rgb(200,200,200)">
                                <b>+</b>
                            </p>
                            <b-row v-if="item.discount || item.discount !== 0">
                                <b-col cols="6">
                                    <p>Sub Total</p>
                                </b-col>
                                <b-col cols="6" class="text-right">
                                    <p>
                                        {{ formatPrice(item.price - item.discount) }}
                                    </p>
                                </b-col>
                            </b-row>
                            <b-row v-if="voucher">
                                <b-col cols="6">
                                    <p>{{ voucher.name }}</p>
                                </b-col>
                                <b-col cols="6" class="text-right">
                                    <p>
                                        - {{ formatPrice(voucher.amount) }}
                                    </p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">
                                    <p v-if="thirdParty == 'Pilih Metode'">Biaya Admin ({{ thirdParty }})</p>
                                    <p v-else>
                                        Biaya Admin
                                    </p>
                                    <img style="max-width: 100px" v-if="thirdParty !== 'Pilih Metode' && thirdParty !== 'Manual'" :src="require('../assets/icon/'+thirdParty+'.png')">
                                    <p v-else><b> Transfer Manual</b></p>
                                </b-col>
                                <b-col cols="6" class="text-right">
                                    <p>
                                        {{ formatPrice(biayaAdmin) }}
                                    </p>
                                </b-col>
                            </b-row>
                            <p class="text-right" style="width:100%; border-bottom: 1px solid rgb(200,200,200)">
                                <b>+</b>
                            </p>
                            <b-row>
                                <b-col cols="6">
                                    <h5>Total</h5>
                                </b-col>
                                <b-col cols="6" class="text-right">
                                    <h5>
                                        {{ formatPrice(finalPrice) }}
                                    </h5>
                                </b-col>
                            </b-row>
                        </div>
                        <template #modal-footer="{ cancel }">
                            <p class="w-100 text-center">
                                Metode pembayaran tidak dapat diubah lagi ketika
                                transaksi sudah dibuat. Apakah kamu sudah yakin? 
                            </p>
                            <!-- Emulate built in modal footer ok and cancel button actions -->
                            <b-row class="mt-2 w-100 text-center">
                                <b-col md="2">
                                
                                </b-col>
                                <b-col md="4">
                                    <b-button 
                                        variant="danger" 
                                        class="mb-2" 
                                        @click="cancel()"
                                        block
                                        >
                                        Batal
                                    </b-button>
                                </b-col>
                                <b-col md="4">
                                    <b-button 
                                        variant="info"
                                        class="mb-2" 
                                        @click="charge()"
                                        block
                                        >
                                        Yakin
                                    </b-button>
                                </b-col>
                                <b-col md="2">
                                
                                </b-col>
                            </b-row>
                        </template>
                    </b-modal>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
    
    <!-- Menunggu Pembayaran -->
    <b-container v-else-if="status == 'pending' && !loading" >
        <b-row>
             <b-col md="8" offset="2" class="h-100">
                <h4 class="stick-top">Rincian Pesanan</h4>
                <b-card
                    header-tag="header" 
                    class="payment-card mb-4"
                >
                    <template #header>
                        <h6 class="mb-0 header-text">Informasi Pesanan</h6>
                    </template>
                    <b-card-text>
                        <b-row>
                            <b-col cols="12">
                                <p class="mb-0">
                                    Order ID
                                </p>
                                <h4>
                                    {{ transaction.orderID }} 
                                    <b-icon 
                                        id="copy-order-id" 
                                        class="float-right icon-copy" 
                                        icon="file-earmark"
                                        style="cursor:pointer"
                                        @click="copyToClipboard(transaction.orderID)"
                                        >
                                    </b-icon>
                                    <b-popover id="popover-order-id" target="copy-order-id" triggers="hover" placement="bottomright">
                                        Copy Order ID
                                    </b-popover>
                                </h4>
                                
                            </b-col>
                        </b-row>
                        <hr style="width: 100%">
                        <b-row>
                            <b-col cols="6">
                                <p>Nama Produk </p>
                            </b-col>
                            <b-col cols="6" class="text-left">
                                <p>
                                    : <b>{{ transaction.productDetails.name }}</b>
                                </p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <p>Pemesan</p>
                            </b-col>
                            <b-col cols="6" class="text-left">
                                <p>
                                    : <b>{{ transaction.userDetails.firstName }}</b>
                                </p>
                            </b-col>
                        </b-row>
                        <hr style="width: 100%">
                        <b-row>
                            <b-col cols="12">
                                <p>
                                    Waktu Pembuatan<br>
                                    <b>{{ formatTanggal(transaction.date.confirmed.toDate()) }}</b>
                                </p>
                            </b-col>
                        </b-row>
                        <b-row v-if="transaction.transactionDetails.paymentType !== 'gform'">
                            <b-col cols="12">
                                <p>
                                    Waktu Kadaluarsa<br>
                                    <b>{{ formatTanggal(new Date(transaction.date.confirmed.toDate().getTime()+(transaction.transactionDetails.expiryTime*60*1000))) }}</b>
                                </p>
                            </b-col>
                        </b-row>

                    </b-card-text>
                </b-card>
            </b-col>
            <b-col md="8" offset="2" class="mb-3">
                <!-- Detail Biaya -->
                <h4>Detail Biaya</h4>
                <b-card
                    header-tag="header" 
                    class="payment-card"
                >
                    <template #header>
                        <h6 class="mb-0 header-text">Rincian Biaya ({{transaction.transactionDetails.thirdParty}})</h6>
                    </template>
                    <img style="max-width: 100px" v-if="transaction.transactionDetails.thirdParty !== 'Manual'" :src="require('../assets/icon/'+transaction.transactionDetails.thirdParty+'.png')">
                    <p v-else><b> Transfer Manual</b></p>
                    <hr style="width:100%">
                    <b-row>
                        <b-col cols="6">
                            <p class="mt-2">Harga Produk</p>
                        </b-col>
                        <b-col cols="6" class="text-right">
                            <p class="mt-2">
                                {{ formatPrice(transaction.amountDetails.itemPrice) }}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row v-if="transaction.amountDetails.discount && transaction.amountDetails.discount !== 0">
                        <b-col cols="6">
                            <p>Diskon</p>
                        </b-col>
                        <b-col cols="6" class="text-right">
                            <p>
                                {{ formatPrice(transaction.amountDetails.discount) }}
                            </p>
                        </b-col>
                    </b-row>
                    <p v-if="transaction.amountDetails.discount && transaction.amountDetails.discount !== 0" class="text-right" style="width:100%; border-bottom: 1px solid rgb(200,200,200)">
                        <b>+</b>
                    </p>
                    <b-row v-if="transaction.amountDetails.discount && transaction.amountDetails.discount !== 0">
                        <b-col cols="6">
                            <p>Sub Total</p>
                        </b-col>
                        <b-col cols="6" class="text-right">
                            <p>
                                {{ formatPrice(transaction.amountDetails.itemPrice + transaction.amountDetails.discount) }}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row v-if="transaction.amountDetails.voucher && transaction.amountDetails.voucher.amount > 0">
                        <b-col cols="6">
                            <p>
                                {{ transaction.amountDetails.voucher.name }}
                            </p>
                        </b-col>
                        <b-col cols="6" class="text-right">
                            <p>
                                -{{ formatPrice(transaction.amountDetails.voucher.amount) }}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <p>Biaya Admin</p>
                        </b-col>
                        <b-col cols="6" class="text-right">
                            <p>
                                {{ formatPrice(transaction.amountDetails.adminFee) }}
                            </p>
                        </b-col>
                    </b-row>
                    <p class="text-right" style="width:100%; border-bottom: 1px solid rgb(200,200,200)">
                        <b>+</b>
                    </p>
                    <b-row>
                        <b-col cols="6">
                            <h5>Total</h5>
                        </b-col>
                        <b-col cols="6" class="text-right">
                            <h5>
                                {{ formatPrice(transaction.amountDetails.grossAmount) }}
                            </h5>
                        </b-col>
                    </b-row>
                </b-card>
                
            </b-col>
            <b-col md="8" offset="2" class="h-100">
                <h4 class="stick-top">Tata Cara Pembayaran</h4>
                <b-card
                    header-tag="header" 
                    class="payment-card mb-4"
                >
                    <template #header>
                        <h6 class="mb-0 header-text">Instruksi Pembayaran ({{ transaction.transactionDetails.thirdParty }})</h6>
                    </template>
                    <!-- Bank VA -->
                     <b-card-text v-if="transaction.transactionDetails.paymentType == 'bank_transfer' || transaction.transactionDetails.paymentType == 'echannel'" class="accordion">
                        <div v-if="transaction.transactionDetails.paymentType == 'bank_transfer'">
                            <p class="mb-0">
                                Nomor VA (Virtual Account)
                            </p>
                            <h3>
                                {{ transaction.vaDetails.virtualAccount }}
                                <b-icon 
                                    id="copy-virtual-account" 
                                    class="float-right icon-copy" 
                                    icon="file-earmark"
                                    style="cursor:pointer"
                                    @click="copyToClipboard(transaction.vaDetails.virtualAccount)"
                                    >
                                </b-icon>
                                <b-popover id="popover-virtual-account" target="copy-virtual-account" triggers="hover" placement="bottomright">
                                    Copy Nomor VA
                                </b-popover>
                            </h3>
                        </div>
                        <div v-else-if="transaction.transactionDetails.paymentType == 'echannel'">
                            <p class="mb-0">
                                Kode Biller (Biller Code)
                            </p>
                            <h3>
                                {{ transaction.vaDetails.billerCode }} 
                                <b-icon 
                                    id="copy-biller-code" 
                                    class="float-right icon-copy" 
                                    icon="file-earmark"
                                    style="cursor:pointer"
                                    @click="copyToClipboard(transaction.vaDetails.billerCode)"
                                    >
                                </b-icon>
                                <b-popover id="popover-biller-code" target="copy-biller-code" triggers="hover" placement="bottomright">
                                    Copy Kode Biller
                                </b-popover>
                            </h3>
                            <p class="mb-0 mt-1">
                                Kode Pembayaran (Bill Key)
                            </p>
                            <h3>
                                {{ transaction.vaDetails.billKey }} 
                                <b-icon 
                                    id="copy-bill-key" 
                                    class="float-right icon-copy" 
                                    icon="file-earmark"
                                    style="cursor:pointer"
                                    @click="copyToClipboard(transaction.vaDetails.billKey)"
                                    >
                                </b-icon>
                                <b-popover id="popover-bill-key" target="copy-bill-key" triggers="hover" placement="bottomright">
                                    Copy Kode Pembayaran
                                </b-popover>
                            </h3>
                        </div>
                        <hr style="width: 100%">
                        <p>Langkah Pembayaran</p>

                        <!-- BNI -->
                        <div v-if="transaction.transactionDetails.thirdParty=='BNI'">
                            <!-- Mobile Banking -->
                            <div
                                v-b-toggle.mobile-banking
                                class="header-metode mb-3"
                                >
                                Mobile Banking
                                <b-icon 
                                    class="float-right" 
                                    icon="caret-down-fill">
                                </b-icon>
                            </div>
                            <b-collapse id="mobile-banking" accordion="instruksi-bni" class="mt-2">
                                <ol class="instruksi-pembayaran">
                                    <li>Buka BNI Mobile Banking melalui smartphone dan lakukan login.</li>
                                    <li>Pilih menu <b>Transfer.</b></li>
                                    <li>Pilih menu <b>Virtual Account Billing</b>, kemudian pilih rekening debet.</li>
                                    <li>Masukkan nomor VA (Virtual Account) yang tertera diatas pada menu <b>Input Baru</b>.</li>
                                    <li>Detail tagihan yang harus dibayar akan muncul.</li>
                                    <li>Jika detail tagihan sudah sesuai, masukkan Password Transaksi.</li>
                                    <li>Pilih <b>Lanjut</b> dan transaksi akan diproses.</li>
                                </ol>
                            </b-collapse>

                            <!-- ATM BNI -->
                             <div
                                v-b-toggle.atm-bni
                                class="header-metode mb-3"
                                >
                                ATM BNI
                                <b-icon 
                                    class="float-right" 
                                    icon="caret-down-fill">
                                </b-icon>
                            </div>
                            <b-collapse id="atm-bni" accordion="instruksi-bni" class="mt-2">
                                <ol class="instruksi-pembayaran">
                                    <li>Pada menu utama, pilih <b>Menu Lainnya</b>.</li>
                                    <li>Pilih <b>Transfer</b>.</li>
                                    <li>Pilih jenis rekening yang akan digunakan (misalnya Rekening Tabungan).</li>
                                    <li>Masukkan nomor VA (Virtual Account) yang tertera diatas, kemudian pilih <b>Tekan</b>.</li>
                                    <li>Masukkan jumlah tagihan yang tepat. Pembayaran akan ditolak jika jumlah tidak sesuai.</li>
                                    <li>Detail informasi akan ditampilkan. Jika sesuai, tekan <b>Ya</b>.</li>
                                    <li>Transaksi selesai.</li>
                                </ol>
                            </b-collapse>

                            <!-- Internet Banking -->
                             <div
                                v-b-toggle.internet-banking
                                class="header-metode mb-3"
                                >
                                Internet Banking
                                <b-icon 
                                    class="float-right" 
                                    icon="caret-down-fill">
                                </b-icon>
                            </div>
                            <b-collapse id="internet-banking" accordion="instruksi-bni" class="mt-2">
                                <ol class="instruksi-pembayaran">
                                    <li>Akses https://ibank.bni.co.id, kemudian tekan <b>Masuk</b>.</li>
                                    <li>Masukkan User ID dan Password.</li>
                                    <li>Pilih <b>Transfer</b> dan pilih <b>Virtual Account Billing</b>.</li>
                                    <li>Masukkan nomor VA (Virtual Account) yang tertera diatas.</li>
                                    <li>Pilih rekening debet yang akan digunakan, lalu tekan <b>Lanjut</b>.</li>
                                    <li>Detail tagihan akan muncul pada layar konfirmasi.</li>
                                    <li>Jika detail sudah benar, masukkan Kode Otentikasi Token.</li>
                                    <li>Notifikasi bahwa transaksi berhasil akan dikirimkan pada Anda.</li>
                                </ol>
                            </b-collapse>
                        </div>

                        <!-- Mandiri Bill -->
                        <div v-else-if="transaction.thirdParty=='Mandiri Bill'">
                            <!-- ATM Mandiri -->
                             <div
                                v-b-toggle.atm-mandiri
                                class="header-metode mb-3"
                                >
                                ATM Mandiri
                                <b-icon 
                                    class="float-right" 
                                    icon="caret-down-fill">
                                </b-icon>
                            </div>
                            <b-collapse id="atm-mandiri" accordion="instruksi-mandiri" class="mt-2">
                                <ol class="instruksi-pembayaran">
                                    <li>Masukkan kartu ATM dan Pin.</li>
                                    <li>Pilih menu <b>Bayar/Beli</b>.</li>
                                    <li>Pilih menu <b>Lainnya</b>, kemudian pilih menu <b>Multipayment</b>.</li>
                                    <li>Masukkan Kode Biller (Biller Code), lalu pilih <b>Benar</b>.</li>
                                    <li>Masukkan Kode Pembayaran (Bill Key), kemudian pilih <b>Benar</b>.</li>
                                    <li>Detail pembayaran akan ditampilkan. Jika sesuai, tekan <b>Ya</b>.</li>
                                    <li>Transaksi selesai.</li>
                                </ol>
                            </b-collapse>

                            <!-- Internet Banking -->
                             <div
                                v-b-toggle.internet-banking
                                class="header-metode mb-3"
                                >
                                Internet Banking
                                <b-icon 
                                    class="float-right" 
                                    icon="caret-down-fill">
                                </b-icon>
                            </div>
                            <b-collapse id="internet-banking" accordion="instruksi-mandiri" class="mt-2">
                                <ol class="instruksi-pembayaran">
                                    <li>Akses Mandiri Internet Banking (https://ibank.bankmandiri.co.id/).</li>
                                    <li>Dari menu utama pilih <b>Pembayaran</b>, lalu pilih <b>Multi Pembayaran/Multi Payment</b>.</li>
                                    <li>Pilih akun Anda pada <b>Dari Akun</b>.</li>
                                    <li>Pada <b>Nama Penagihan</b> pilih Midtrans.</li>
                                    <li>Masukkan Kode Pembayaran, kemudian Anda akan mendapatkan detail pembayaran.</li>
                                    <li>Konfirmasi pembayaran menggunakan Token Mandiri.</li>
                                </ol>
                            </b-collapse>

                        </div>

                        <!-- Permata Bank -->
                        <div v-else-if="transaction.transactionDetails.thirdParty=='Bank Permata'">
                            <!-- ATM Mandiri -->
                             <div
                                v-b-toggle.permata-va
                                class="header-metode mb-3"
                                >
                                Permata VA (Virtual Account)
                                <b-icon 
                                    class="float-right" 
                                    icon="caret-down-fill">
                                </b-icon>
                            </div>
                            <b-collapse id="permata-va" accordion="instruksi-permata" class="mt-2">
                                <ol class="instruksi-pembayaran">
                                    <li>Pada menu utama, pilih <b>Transaksi Lainnya</b>.</li>
                                    <li>Pilih <b>Pembayaran</b>, kemudian pilih <b>Pembayaran Lainnya</b>.</li>
                                    <li>Pilih <b>Virtual Account</b>.</li>
                                    <li>Masukkan nomor VA (Virtual Account), lalu pilih <b>Benar</b>.</li>
                                    <li>Pada halaman konfirmasi transfer akan muncul detail transaksi. Jika sudah benar, tekan <b>Benar</b>.</li>
                                    <li>Pilih rekening pembayaran Anda, kemudian tekan <b>Benar</b>.</li>
                                    <li>Transaksi selesai.</li>
                                </ol>
                            </b-collapse>

                        </div>

                    </b-card-text>

                    <!-- GoPay -->
                    <b-card-text v-else-if="transaction.transactionDetails.paymentType == 'gopay'">
                        Selesaikan pembayaran transaksi ini melalui aplikasi Gojek kamu.
                    </b-card-text>

                    <!-- Manual -->
                    <b-card-text v-else-if="transaction.transactionDetails.paymentType == 'gform'">
                        Kamu dapat membayar secara manual melalui beberapa metode pembayaran sebagai berikut: 

                        <!-- Transfer Manual -->
                        <div
                            v-b-toggle.verifikasi-manual
                            class="header-metode mb-3"
                            >
                            Metode Pembayaran
                            <b-icon 
                                class="float-right" 
                                icon="caret-down-fill">
                            </b-icon>
                        </div>
                        <b-collapse id="verifikasi-manual" class="mt-2">
                            <ol>
                                <li>
                                    <b>Ovo / Dana / LinkAja</b> <br>
                                    088741070788 A/N Hilmy Muktafi
                                </li>
                                <li>
                                    <b>Jenius</b> <br>
                                    $xhilmy A/N Hilmy Muktafi
                                </li>
                                <li>
                                    <b>Bank BTPN</b> <br>
                                    No. Rek 90012452204 A/N Hilmy Muktafi
                                </li>
                                <li>
                                    <b>Bank BRI</b> <br>
                                    No. Rek 0587-01-001213-56-5 A/N CV Xomodo Global Teknologi
                                </li>
                            </ol>
                        </b-collapse>

                        Jika sudah membayar, jangan lupa untuk mengunggah / upload bukti pembayaran kamu 
                        dengan menekan tombol <b>Unggah Bukti Pembayaran</b> di bawah ini.<br>
                        Kamu akan mendapatkan pemberitahuan melalui email jika berhasil mengunggah bukti pembayaran. 
                        Kemudian silahkan menunggu waktu verifikasi bukti pembayaran dengan jangka waktu paling lambat <b>2x24 jam 
                        dari waktu pengunggahan</b>.
                    </b-card-text>

                    <hr style="width:100%"> 
                    <b-row>
                        <b-col cols="12">
                            <b-button
                                v-if="transaction.transactionDetails.paymentType !== 'gform'"
                                block
                                variant="success"
                                @click="refreshTransaction()"
                            >
                                Konfirmasi Selesai Pembayaran
                            </b-button>
                            <b-button
                                v-else
                                block
                                variant="success"
                                :href="`https://docs.google.com/forms/d/e/1FAIpQLScdeUbeD3znxdhWsMX5x534Z_SWXloSTAGogrDAAtjpKx5sHA/viewform?usp=pp_url&entry.924668649=${orderID}`"
                                target="_blank"
                            >
                                Unggah Bukti Pembayaran
                            </b-button>
                        </b-col>
                        <b-col cols="12" class="mt-2">
                            <b-button
                                block
                                variant="danger"
                                @click="modal.modalBatal = true;"
                            >
                                Batalkan Transaksi
                            </b-button>
                        </b-col>
                        <b-modal 
                            centered 
                            v-model="modal.modalBatal" 
                            id="modal-batal"
                        >
                            <template #modal-header>
                                <b class="text-center w-100">Konfirmasi Pembatalan</b>
                            </template>
                            <div>
                                <p class="w-100 text-center">
                                    Transaksi ini akan kamu batalkan dan tidak dapat 
                                    dikembalikan lagi ketika kamu menekan tombol <b>Batalkan Transaksi</b>.
                                    Apakah kamu sudah yakin?
                                </p>
                            </div>
                            <template #modal-footer="{ cancel }">
                                <!-- Emulate built in modal footer ok and cancel button actions -->
                                <b-row class="mt-2 w-100 text-center">
                                    <b-col md="6">
                                        <b-button 
                                            variant="success" 
                                            class="mb-2" 
                                            @click="cancel()"
                                            block
                                            >
                                            Tutup
                                        </b-button>
                                    </b-col>
                                    <b-col md="6">
                                        <b-button 
                                            variant="danger" 
                                            class="mb-2" 
                                            @click="modal.modalBatal = false; cancelTransaction()"
                                            block
                                            >
                                            Batalkan Transaksi
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </template>
                        </b-modal>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </b-container>

    <!-- Transaksi Sukses -->
    <b-container v-else-if="status == 'success' && !loading" >
        <b-row>
            <b-col lg="2" md="1"></b-col>
            <b-col lg="8" md="10">
                <b-card
                    header-tag="header" 
                    class="payment-card"
                >
                    <template #header>
                        <h6 class="mb-0 header-text text-center">Transaksi Sukses</h6>
                    </template>
                    <b-card-text>
                        <p style="text-align:justify">
                            Selamat, transaksi ini sudah kamu selesaikan. Kamu sekarang bisa mengakses item yang terdaftar dalam <b>{{ transaction.productDetails.name }}</b> 
                            pada Analitica mobile maupun web. Terimakasih atas kepercayaan kamu terhadap kami. Jangan segan untuk memberi kritik dan saran 
                            melalui narahubung kami ya!
                        </p>
                        <hr class="w-100">
                         <div
                            v-b-toggle.collapse-detail-pesanan
                            class="header-metode mb-3 w-100"
                            >
                            Detail Pesanan
                            <b-icon 
                                class="float-right" 
                                icon="caret-down-fill">
                            </b-icon>
                        </div>
                        <b-collapse id="collapse-detail-pesanan" accordion="failed-accordion">
                            <b-row>
                                <b-col class="mb-2" cols="12">
                                    <p class="mb-0">
                                        Order ID
                                    </p>
                                    <h4>
                                        {{ transaction.orderID }} 
                                        <b-icon 
                                            id="copy-order-id" 
                                            class="icon-copy ml-3" 
                                            icon="file-earmark"
                                            style="cursor:pointer"
                                            @click="copyToClipboard(transaction.orderID)"
                                            >
                                        </b-icon>
                                        <b-popover id="popover-order-id" target="copy-order-id" triggers="hover" placement="bottomright">
                                            Copy Order ID
                                        </b-popover>
                                    </h4>
                                    
                                </b-col>
                                <b-col md="6">
                                    <p>
                                        Nama Produk<br>
                                        <b>{{ transaction.productDetails.name }}</b>
                                    </p>
                                </b-col>
                                <b-col md="6">
                                    <p>
                                        Pemesan<br>
                                        <b>{{ transaction.userDetails.firstName }}</b>
                                    </p>
                                </b-col>
                                <b-col md="6">
                                    <p>
                                        Waktu Pembuatan<br>
                                        <b>{{ formatTanggal(transaction.date.confirmed.toDate()) }}</b>
                                    </p>
                                </b-col>
                                <b-col md="6">
                                    <p>
                                        Waktu Transaksi Sukses<br>
                                        <b>{{ formatTanggal(transaction.date.success.toDate()) }}</b>
                                    </p>
                                </b-col>
                                <b-col md="6">
                                    <p>
                                        Metode Pembayaran<br>
                                        <img v-if="transaction.transactionDetails.thirdParty !== 'Manual'" style="max-width: 100px" :src="require('../assets/icon/'+transaction.transactionDetails.thirdParty+'.png')">
                                        <b v-else> Transfer Manual</b>
                                    </p>
                                </b-col>
                            </b-row>
                        </b-collapse>
                        <div
                            v-b-toggle.collapse-rincian-biaya
                            class="header-metode mb-3 w-100"
                            >
                            Rincian Biaya
                            <b-icon 
                                class="float-right" 
                                icon="caret-down-fill">
                            </b-icon>
                        </div>
                        <b-collapse id="collapse-rincian-biaya" accordion="failed-accordion">
                            <b-row>
                                <b-col md="6">
                                    <p>
                                        Harga Produk ({{ transaction.productDetails.name }})<br>
                                        <b>{{ formatPrice(transaction.amountDetails.itemPrice) }}</b>
                                    </p>
                                </b-col>
                                 <b-col md="6" v-if="transaction.amountDetails.discount && transaction.amountDetails.discount !== 0">
                                    <p>
                                        Diskon<br>
                                        <b>
                                            {{ formatPrice(transaction.amountDetails.discount) }}
                                        </b>
                                    </p>
                                </b-col>
                                <b-col md="6" v-if="transaction.amountDetails.voucher">
                                    <p>
                                        {{ transaction.amountDetails.voucher.name }}<br>
                                        <b>
                                             -{{ formatPrice(transaction.amountDetails.voucher.amount) }}
                                        </b>
                                    </p>
                                </b-col>
                                <b-col md="6">
                                    <p>
                                        Biaya Admin<br>
                                        <b>
                                            {{ formatPrice(transaction.amountDetails.adminFee) }}
                                        </b>
                                    </p>
                                </b-col>
                                <b-col md="6">
                                    <p>
                                        Total<br>
                                        <b>
                                            {{ formatPrice(transaction.amountDetails.grossAmount) }}
                                        </b>
                                    </p>
                                </b-col>
                            </b-row>
                        </b-collapse>
                        <hr class="w-100">
                        <b-row>
                            <b-col cols="12" class="text-center p-2">
                                <a
                                    v-if="!isMobile"
                                    href="https://web.analitica.id/"
                                    style="text-decoration: none; padding: 10px;"
                                    target="_blank"
                                    class="submit-button"
                                >
                                    Masuk Analitica Web
                                </a>
                                <a
                                    v-else
                                    href="http://web.analitica.id/download"
                                    style="text-decoration: none; padding: 10px;"
                                    target="_blank"
                                    class="submit-button"
                                >
                                    Kembali ke Analitica
                                </a>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col lg="2" md="1"></b-col>
        </b-row>
    </b-container>

    <!-- Transaksi Gagal -->
    <b-container v-else-if="status == 'failed' || status == 'challenge' || status == 'canceled' && !loading" >
        <b-row>
            <b-col lg="2" md="1"></b-col>
            <b-col lg="8" md="10">
                <b-card
                    header-tag="header" 
                    class="payment-card"
                >
                    <template #header>
                        <h6 class="mb-0 header-text">Transaksi Gagal</h6>
                    </template>
                    <b-card-text>
                        <p style="text-align:justify">
                            Transaksi ini gagal dengan status <b>{{ (status == 'canceled') ? status : transaction.transactionDetails.transactionStatus }}</b>. Kalau kamu ingin 
                            membuat transaksi yang baru, tekan tombol <b>Ulangi Transaksi melalui Aplikasi</b> di bawah.<br>
                        </p>
                        <hr class="w-100">
                         <div
                            v-b-toggle.collapse-detail-pesanan
                            class="header-metode mb-3 w-100"
                            >
                            Detail Pesanan
                            <b-icon 
                                class="float-right" 
                                icon="caret-down-fill">
                            </b-icon>
                        </div>
                        <b-collapse id="collapse-detail-pesanan" accordion="failed-accordion">
                            <b-row>
                                <b-col class="mb-2" cols="12">
                                    <p class="mb-0">
                                        Order ID
                                    </p>
                                    <h4>
                                        {{ transaction.orderID }} 
                                        <b-icon 
                                            id="copy-order-id" 
                                            class="icon-copy ml-3" 
                                            icon="file-earmark"
                                            style="cursor:pointer"
                                            @click="copyToClipboard(transaction.orderID)"
                                            >
                                        </b-icon>
                                        <b-popover id="popover-order-id" target="copy-order-id" triggers="hover" placement="bottomright">
                                            Copy Order ID
                                        </b-popover>
                                    </h4>
                                    
                                </b-col>
                                <b-col md="6">
                                    <p>
                                        Nama Produk<br>
                                        <b>{{ transaction.productDetails.name }}</b>
                                    </p>
                                </b-col>
                                <b-col md="6">
                                    <p>
                                        Pemesan<br>
                                        <b>{{ transaction.userDetails.firstName }}</b>
                                    </p>
                                </b-col>
                                <b-col md="6">
                                    <p>
                                        Waktu Pembuatan<br>
                                        <b>{{ formatTanggal(transaction.date.confirmed.toDate()) }}</b>
                                    </p>
                                </b-col>
                                <!--<b-col md="6">
                                    <p>
                                        Waktu Transaksi Gagal<br>
                                        <b>{{ formatTanggal(transaction.date.failed.toDate()) }}</b>
                                    </p>
                                </b-col>-->
                                <b-col md="6">
                                    <p>
                                        Metode Pembayaran<br>
                                        <img style="max-width: 100px" v-if="transaction.transactionDetails.thirdParty !== 'Manual'" :src="require('../assets/icon/'+transaction.transactionDetails.thirdParty+'.png')">
                                        <b v-else> Transfer Manual</b>
                                    </p>
                                </b-col>
                            </b-row>
                        </b-collapse>
                        <div
                            v-b-toggle.collapse-rincian-biaya
                            class="header-metode mb-3 w-100"
                            >
                            Rincian Biaya
                            <b-icon 
                                class="float-right" 
                                icon="caret-down-fill">
                            </b-icon>
                        </div>
                        <b-collapse id="collapse-rincian-biaya" accordion="failed-accordion">
                            <b-row>
                                <b-col md="6">
                                    <p>
                                        Harga Produk ({{ transaction.productDetails.name }})<br>
                                        <b>{{ formatPrice(transaction.amountDetails.itemPrice) }}</b>
                                    </p>
                                </b-col>
                                <b-col md="6" v-if="transaction.amountDetails.discount && transaction.amountDetails.discount !== 0">
                                    <p>
                                        Diskon<br>
                                        <b>
                                            {{ formatPrice(transaction.amountDetails.discount) }}
                                        </b>
                                    </p>
                                </b-col>
                                <b-col md="6" v-if="transaction.amountDetails.voucher">
                                    <p>
                                        {{ transaction.amountDetails.voucher.name }}<br>
                                        <b>
                                             -{{ formatPrice(transaction.amountDetails.voucher.amount) }}
                                        </b>
                                    </p>
                                </b-col>
                                <b-col md="6">
                                    <p>
                                        Biaya Admin<br>
                                        <b>
                                            {{ formatPrice(transaction.amountDetails.adminFee) }}
                                        </b>
                                    </p>
                                </b-col>
                                <b-col md="6">
                                    <p>
                                        Total<br>
                                        <b>
                                            {{ formatPrice(transaction.amountDetails.grossAmount) }}
                                        </b>
                                    </p>
                                </b-col>
                            </b-row>
                        </b-collapse>
                        <hr class="w-100">
                        <b-row>
                            <b-col cols="12">
                                <p style="font-size:14px; text-align:justify">
                                    <i>Note </i>: Jika kamu merasa terjadi kesalahan (sudah membayar tapi transaksi dianggap gagal atau keluhan lainnya), kamu dapat 
                                    menghubungi narahubung atau <i>contact person</i> Analitica yang tertera 
                                    di bagian bawah halaman.
                                </p>
                            </b-col>
                            <hr class="w-100">
                            <b-col cols="12">
                                <button
                                    @click="newTransaction()"
                                    class="submit-button"
                                >
                                    Ulangi Transaksi melalui Aplikasi
                                </button>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col lg="2" md="1"></b-col>
        </b-row>
    </b-container>

    <b-container fluid class="footer">
        <!-- footer -->
        <b-row class="p-md-5 py-5">
            <b-col md="2"></b-col>
            <b-col cols="6" md="4" class="text-left">
                <p style="font-size: 16px">
                    Green Semanggi Mangroove C-14, <br>
                    Wonorejo Timur, Wonorejo, <br>
                    Rungkut, Surabaya
                </p>
            </b-col>
            <b-col cols="6" md="4" class="text-right">
                <p style="font-size: 16px">
                    Ada pertanyaan?<br>
                    cs@analitica.id - email<br>
                    (+62) 887-4107-0788 - whatsaap
                </p>
            </b-col>
            <b-col md="2"></b-col>
        </b-row>
        <b-row class="px-md-5 pb-5">
            <b-col md="2"></b-col>
            <b-col cols="12" md="4" class="text-left">
                <img class="mb-2" src="../assets/logo-analitica-black.png" alt="Analitica Logo" style="max-height:40px"><br>
                <p>© 2021 Analitica. All Rights Reserved.</p>
            </b-col>
            <b-col cols="12" md="4" class="text-right">
                <a href="https://www.instagram.com/analitica.id/" target="_blank">
                    <b-icon class="mr-3 icon-media" icon="instagram"></b-icon>
                </a>
                <a href="https://www.facebook.com/analitica.id" target="_blank">
                    <b-icon class="mr-3 icon-media" icon="facebook"></b-icon>
                </a>
                <a href="https://twitter.com/analitica_id" target="_blank">
                    <b-icon class="icon-media" icon="twitter"></b-icon>
                </a>
            </b-col>
            <b-col md="2"></b-col>
        </b-row>
    </b-container>
    
  </div>
</template>
<script
    type="text/javascript"
    src="https://app.midtrans.com/snap/snap.js"
    data-client-key="Mid-client-3bCQ4H9IK26wPTur"
>
</script>

<script>
//import HelloWorld from '../components/HelloWorld.vue'
import firebase from '../plugins/firebase.js'
import debounce from 'debounce'
    // src="https://app.sandbox.midtrans.com/snap/snap.js"
    // data-client-key="SB-Mid-client-Oh-4CawaJQUQsGBB"
export default {
    name: 'PaySnap',
    data() {
        return {
            count: 0,
            orderID: "",
            grossAmount: 0,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            response: "",
            uid: "",
            iid: "",
            srid: "",
            paymentType: "",
            enabledPayments: [],
            bank: "",
            status: "",
            item: "",
            activePayment: "",
            activeNumber: 1,
            voucherCode: "",
            voucher: null,
            voucherIsValid: null,
            thirdParty: "Pilih Metode",
            biayaAdmin: 0,
            metode: "",

            isMobile: false,
            voucherLoading: false,
            loading: true,
            loadingMessage: "Tunggu ya Sob, pembayaranmu sedang diproses!",
            isError:false,
            disableConfirm: false,

            modal:{
                modalKonfirmasi: false,
                modalError: false,
                modalBatal: false,
            },

            logoManual:[
                'ovo',
                'dana',
                'linkaja',
                'jenius',
                'bank-bri',
                'bank-btpn',
            ]
        }
    },
    computed:{
        finalPrice(){
            const vm = this;
            let finalPrice = vm.item.price;
            finalPrice += vm.biayaAdmin;
            finalPrice -= vm.voucher ? vm.voucher.amount : 0;
            finalPrice -= vm.item ? vm.item.discount : 0;

            return finalPrice
        },
    },
    watch:{
        voucherCode: debounce(function(){
            const vm = this;
            vm.checkVoucher();
        }, 500),
        metode(){
            const vm = this;
            vm.pilihMetode(vm.metode);
        },
    },
    methods: {
        formatTanggal(date){
            const bulan = [
                "Januari", 
                "Februari", 
                "Maret", 
                "April", 
                "Mei", 
                "Juni", 
                "Juli", 
                "Agustus",
                "September", 
                "Oktober", 
                "November", 
                "Desember"
            ];

            let hour = (date.getHours().toString().length == 1) ? "0"+date.getHours().toString() : date.getHours().toString();
            let minute = (date.getMinutes().toString().length == 1) ? "0"+date.getMinutes().toString() : date.getMinutes().toString();
            let second = (date.getSeconds().toString().length == 1) ? "0"+date.getSeconds().toString() : date.getSeconds().toString();
            
            let newDate = date.getDate()+" "+bulan[date.getMonth()]+" "+date.getFullYear()+", "+hour+":"+minute+" WIB";
            return newDate;
        },
        formatPrice(price){
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'IDR',

                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            });

            return formatter.format(price);
        },
        async checkVoucher(){
            const vm = this;

            vm.voucher = null;
            vm.voucherIsValid = null;

            if(!vm.voucherCode){
                vm.voucherIsValid = null;
            }
            else{
                const checkVoucher = firebase.app().functions('asia-southeast2').httpsCallable('checkVoucher');
                vm.voucherLoading = true;
                vm.disableConfirm = true;
                await checkVoucher({
                    prid: vm.transaction.prid,
                    srid: vm.transaction.source,
                    voucherCode: vm.voucherCode.trim()
                })
                .then(res => {
                    if(res.data.msg == 'success'){
                        vm.voucherIsValid = true;
                        vm.voucher = res.data.data;
                    }
                    else{
                        vm.voucherIsValid = false;
                        vm.voucher = res.data.data;
                    }
                })
                .catch(err => {
                    vm.voucherIsValid = null;
                    vm.voucher = null;
                    console.log(err)
                })
                vm.voucherLoading = false;
                vm.disableConfirm = false;
            }
        },

        async newTransaction(){
            const vm = this;
            // const db = firebase.firestore();
            vm.loading = true;

            if(vm.isMobile){
                window.location.href="https://web.analitica.id/download"
            }
            else{
                window.location.href="https://web.analitica.id/"
            }

            // await db.collection(`sources/${vm.srid}/items`).doc(vm.iid)
            //     .get()
            //     .then(snapShot => {
            //         vm.item = snapShot.data();
            //         vm.orderID = uniqid();
            //         vm.loading = false;
            //         vm.activeNumber = 1;
            //         vm.status = "new";
            //     })
            //     .catch(err => {
            //         console.log(err)
            //     })

        },
        async refreshTransaction(){
            const vm = this;
            const refreshTransactionStatus = firebase.app().functions('asia-southeast2').httpsCallable('refreshTransactionStatus');

            vm.loading = true;

            await refreshTransactionStatus({
                    orderID: vm.orderID
                })
                .then(res => {
                    if(res.data.msg !== vm.status){
                        vm.$router.push({path: `/${vm.orderID}/${res.data.msg}`})
                    }
                    vm.checkCurrentStatus();
                })
                .catch(err => {
                    vm.isError = true;
                    vm.loadingMessage = res.message
                    console.log(err)
                })
        },
        async cancelTransaction(){
            const vm = this;
            const cancelTransaction = firebase.app().functions('asia-southeast2').httpsCallable('cancelTransaction');

            vm.loading = true;

            await cancelTransaction({
                    orderID: vm.orderID
                })
                .then(() => {
                    vm.$router.push({path: `/${vm.orderID}/canceled`})
                    vm.checkCurrentStatus();
                })
                .catch(err => {
                    vm.isError = true;
                    vm.loadingMessage = res.message
                    console.log(err)
                })
        },
        async checkCurrentStatus(){
            const vm = this;
            const db = firebase.firestore();
            const uniqid = require('uniqid');
            const MobileDetect = require('mobile-detect');
            let md = new MobileDetect(window.navigator.userAgent);
            let loading = true;

            // vm.uid = vm.$route.params.uid;
            // vm.iid = vm.$route.params.iid;
            // vm.srid = vm.$route.params.srid;

            //checking for mobile/tablet
            if(md.mobile() || md.phone() || md.tablet() || md.is('iPhone')){
                vm.isMobile = true;
            }
            else{
                vm.isMobile = false;
            }

            //await vm.acquireNotification();

            await db.collection('purchases').doc(vm.orderID)
                    .get()
                    .then(async snapShot => {
                        vm.transaction = snapShot.data();
                        if(snapShot.data().status == 'new'){
                            console.log("masuk new")
                            //kalau belum ada dokumen order, ambil info item
                            vm.item = snapShot.data().productDetails;
                            //vm.orderID = uniqid();
                            console.log(vm.item)
                            vm.loading = false;
                            vm.activeNumber = 1;
                            vm.status = "new";
                            // await db.collection(`sources/${vm.srid}/items`).doc(vm.iid)
                            //         .get()
                            //         .then(snapShot => {
                            //         })
                            //         .catch(err => {
                            //             console.log(err)
                            //         })
                        }
                        else{
                            const refreshTransactionStatus = firebase.app().functions('asia-southeast2').httpsCallable('refreshTransactionStatus');
                            
                            let snapShot = snapShot

                            //pengecekan pembatalan atau bukan
                            if(vm.transaction.status !== 'canceled'){
                                //bukan pembatalan
                                await refreshTransactionStatus({
                                    orderID: vm.orderID
                                })
                                .then(async () => {
                                    await db.collection('purchases').doc(vm.transaction.orderID)
                                        .get()
                                        .then(async doc => {
                                            vm.transaction = doc.data();
                                                //cek transaction status
                                            if(doc.data().transactionDetails.transactionStatus == "pending"){
                                                //transaksi sudah ada, tapi belum diselesaikan
                                                vm.activeNumber = 2;
                                                vm.loading = false;
                                                vm.status = "pending";
                                                vm.orderID = doc.data().orderID;
                                            }
                                            else if(doc.data().transactionDetails.transactionStatus == "settlement"){
                                                //transaksi ini sudah selesai dan sukses
                                                vm.activeNumber = 3;
                                                vm.status = "success";
                                                vm.loading = false;
                                            }
                                            else if(doc.data().transactionDetails.transactionStatus == "capture"){
                                                //masih ada potensi penipuan
                                                if(doc.data().transactionDetails.fraudStatus){
                                                    if(doc.data().transactionDetails.fraudStatus == "accept"){
                                                        vm.status = "success";
                                                    }
                                                    else if(doc.data().transactionDetails.fraudStatus == "deny"){
                                                        vm.status = "failed";
                                                    }
                                                    else if(doc.data().transactionDetails.fraudStatus == "challenge"){
                                                        vm.status = "challenge";
                                                    }
                                                }
                                                vm.activeNumber = 3;
                                                vm.loading = false;
                                            }
                                            else{
                                                //error, expired, ditolak
                                                vm.activeNumber = 3;
                                                vm.status = "failed";
                                                vm.loading = false;
                                            }
                                            
                                        })
                                        .catch(err => {
                                            console.log(err)
                                        })
                                })
                                .catch(err => {
                                    console.log(err)
                                })
                            }
                            else{
                                console.log("masuk canceled")
                                vm.activeNumber = 3;
                                vm.status = "canceled";
                                vm.loading = false;
                                // if(snapShot.data().transactionDetails.transactionStatus == "pending"){
                                //     //transaksi sudah ada, tapi belum diselesaikan
                                //     vm.activeNumber = 2;
                                //     vm.loading = false;
                                //     vm.status = "pending";
                                //     vm.orderID = snapShot.data().orderID;
                                // }
                                // else if(snapShot.data().transactionDetails.transactionStatus == "settlement"){
                                //     //transaksi ini sudah selesai dan sukses
                                //     vm.activeNumber = 3;
                                //     vm.status = "success";
                                //     vm.loading = false;
                                // }
                                // else if(snapShot.data().transactionDetails.transactionStatus == "capture"){
                                //     //masih ada potensi penipuan
                                //     if(snapShot.data().fraudStatus){
                                //         if(snapShot.data().transactionDetails.fraudStatus == "accept"){
                                //             vm.status = "success";
                                //         }
                                //         else if(snapShot.data().transactionDetails.fraudStatus == "deny"){
                                //             vm.status = "failed";
                                //         }
                                //         else if(snapShot.data().transactionDetails.fraudStatus == "challenge"){
                                //             vm.status = "challenge";
                                //         }
                                //     }
                                //     vm.activeNumber = 3;
                                //     vm.loading = false;
                                // }
                                // else{
                                //     //error, expired, ditolak
                                //     vm.activeNumber = 3;
                                //     vm.status = "failed";
                                //     vm.loading = false;
                                // }
                            }

                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
        },
        pilihMetode(metode){
            const vm = this;

            switch(metode){
                case "bni": 
                    vm.bank='bni'; 
                    vm.paymentType='bank_transfer'; 
                    vm.enabledPayments=  ['bni_va'];
                    vm.thirdParty='BNI';
                    vm.biayaAdmin=4000;
                    break;
                case "permata":
                    vm.bank='';
                    vm.paymentType='permata'; 
                    vm.enabledPayments=  ['permata_va'];
                    vm.thirdParty='Bank Permata';
                    vm.biayaAdmin=4000;
                    break;
                case "mandiribill":
                    vm.bank='';
                    vm.paymentType='echannel'; 
                    vm.enabledPayments=  ['echannel'];
                    vm.thirdParty='Mandiri Bill';
                    vm.biayaAdmin=4000;
                    break;
                case "gopay":
                    vm.bank='';
                    vm.paymentType='gopay'; 
                    vm.enabledPayments=  ['gopay'];
                    vm.thirdParty='GoPay';
                    vm.biayaAdmin = 1000;
                    break;
                case 'gform':
                    vm.bank='';
                    vm.paymentType='gform'; 
                    vm.enabledPayments=  ['gform'];
                    vm.thirdParty='Manual';
                    vm.biayaAdmin = 0;
            }
        },
        selectText(element) {
            var range;
            if (document.selection) {
                // IE
                range = document.body.createTextRange();
                range.moveToElementText(element);
                range.select();
            } else if (window.getSelection) {
                range = document.createRange();
                range.selectNode(element);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
        },
        copyToClipboard(str){
            const el = document.createElement('textarea');
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            // switch(stringElement){
            //     case "orderID":
            //         this.selectText(this.$refs.orderIDElement); // e.g. <div ref="text">
            //         document.execCommand("copy");
            //         break;
            //     case "virtualAccount":
            //         this.selectText(this.$refs.VAElement); // e.g. <div ref="text">
            //         document.execCommand("copy");
            //         break;
            // }
            
        },
        async acquireToken(){
            const vm = this;
            const acquireTransactionToken = firebase.app().functions('asia-southeast2').httpsCallable('acquireTransactionToken');
   
            await acquireTransactionToken({
                orderID: vm.orderID,
                firstName: vm.firstName,
                lastName: vm.lastName,
                email: vm.email,
                phone: vm.phone
            })
            .then(res => {
                vm.loading = false;
                window.snap.pay(res.data);
            })
            .catch(err => {
                console.log(err)
            })
        },
        async charge(){
            const vm = this;
            //const chargeTransaction = firebase.app().functions('asia-southeast2').httpsCallable('chargeTransaction');
            const acquireTransactionToken = firebase.app().functions('asia-southeast2').httpsCallable('acquireTransactionToken');
            const newPurchase = firebase.app().functions('asia-southeast2').httpsCallable('newPurchase');

            vm.modal.modalKonfirmasi = false;
            vm.loading = true;

            if(vm.paymentType !== 'gform'){
                await acquireTransactionToken({
                    uid: vm.transaction.uid,
                    prid: vm.transaction.prid,
                    srid: vm.transaction.source,
                    orderID: vm.orderID,
                    biayaAdmin: vm.biayaAdmin,
                    enabledPayments: vm.enabledPayments,
                    voucher: vm.voucher,
                    voucherCode: vm.voucherCode.trim(),
                })
                    .then(res => {
                        vm.loading = false;
                        window.snap.pay(res.data, {
                            onSuccess: async function(result){
                                /* You may add your own implementation here */
                                vm.loading = true;

                                await newPurchase({
                                    uid: vm.transaction.uid,
                                    prid: vm.transaction.prid,
                                    srid: vm.transaction.source,
                                    chargeResponse: result,
                                    biayaAdmin: vm.biayaAdmin,
                                    voucher: vm.voucher,
                                    voucherCode: vm.voucherCode.trim(),
                                    enabledPayments: vm.enabledPayments,
                                    orderID: vm.orderID,
                                })
                                .then(res => {
                                    if(res.data.msg == 'success'){
                                        vm.$router.push({path: `/${vm.orderID}/success`})
                                        vm.checkCurrentStatus();
                                    }
                                    else{
                                        vm.isError = true;
                                        vm.loadingMessage = "Gagal membuat dokumen transaksi"
                                        console.log('Failed,',res.data)
                                    }
                                })
                                .catch(err => {
                                    vm.isError = true;
                                    vm.loadingMessage = err.message
                                    console.log(err)
                                })
                            },
                            onPending: async function(result){
                                /* You may add your own implementation here */
                                vm.loading = true;
                                
                                await newPurchase({
                                    uid: vm.transaction.uid,
                                    prid: vm.transaction.prid,
                                    srid: vm.transaction.source,
                                    chargeResponse: result,
                                    biayaAdmin: vm.biayaAdmin,
                                    voucher: vm.voucher,
                                    voucherCode: vm.voucherCode.trim(),
                                    enabledPayments: vm.enabledPayments,
                                    orderID: vm.orderID,
                                })
                                .then(res => {
                                    if(res.data.msg == 'success'){
                                        vm.$router.push({path: `/${vm.orderID}/pending`})
                                        vm.checkCurrentStatus();
                                    }
                                    else{
                                        vm.isError = true;
                                        vm.loadingMessage = "Gagal membuat dokumen transaksi"
                                        console.log('Failed,',res.data)
                                    }
                                })
                                .catch(err => {
                                    vm.isError = true;
                                    vm.loadingMessage = err.message
                                    console.log(err)
                                })
                            },
                            onError: async function(result){
                                /* You may add your own implementation here */
                                vm.loading = true;

                                await newPurchase({
                                    uid: vm.transaction.uid,
                                    prid: vm.transaction.prid,
                                    srid: vm.transaction.source,
                                    chargeResponse: result,
                                    biayaAdmin: vm.biayaAdmin,
                                    voucher: vm.voucher,
                                    voucherCode: vm.voucherCode.trim(),
                                    enabledPayments: vm.enabledPayments,
                                    orderID: vm.orderID,
                                })
                                .then(res => {
                                    if(res.data.msg == 'success'){
                                        vm.$router.push({path: `/${vm.orderID}/failed`})
                                        vm.checkCurrentStatus();
                                    }
                                    else{
                                        vm.isError = true;
                                        vm.loadingMessage = "Gagal membuat dokumen transaksi"
                                        console.log('Failed,',res.data)
                                    }
                                })
                                .catch(err => {
                                    vm.isError = true;
                                    vm.loadingMessage = err.message
                                    console.log(err)
                                })
                            },
                            onClose: function(){
                                /* You may add your own implementation here */
                                alert('Transaksi dibatalkan.');
                            }
                        });
                })
                .catch(err => {
                    vm.isError = true;
                    vm.loadingMessage = err.message
                    console.log(err)
                })
            }
            else{
                await newPurchase({
                        uid: vm.transaction.uid,
                        prid: vm.transaction.prid,
                        srid: vm.transaction.source,
                        chargeResponse: null,
                        biayaAdmin: vm.biayaAdmin,
                        voucher: vm.voucher,
                        voucherCode: vm.voucherCode.trim(),
                        enabledPayments: vm.enabledPayments,
                        orderID: vm.orderID,
                    })
                    .then(res => {
                        if(res.data.msg == 'success'){
                            vm.$router.push({path: `/${vm.orderID}/pending`})
                            vm.checkCurrentStatus();
                        }
                        else{
                            vm.isError = true;
                            vm.loadingMessage = "Gagal membuat dokumen transaksi"
                            console.log('Failed,',res.data)
                        }
                    })
                    .catch(err => {
                        vm.isError = true;
                        vm.loadingMessage = err.message
                        console.log(err)
                    })
            }
        }
    },
    async beforeMount(){
        const vm = this;
        vm.orderID = vm.$route.params.orderid
        await vm.checkCurrentStatus();

        //console.log(tes);
    },
    // async mounted(){
    //     const vm = this;

    //     await vm.checkCurrentStatus();
    // },
}
</script>

<style scoped>
body, html{
    height: 100%;
    font-family: 'Poppins', sans-serif;
}
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.card-header{
    background-color: #EC008C;
}
.header-text{
    color:white;
}
.payment-card{
    border-radius: 20px;
}
.bg-container{
    background-color: white;
}
.cutted-price{
    text-decoration: line-through;
    color: rgba(80,80,80);
    font-size: 16px !important;
}
.price{
    font-size: 18px;
}
.header-metode{
    width: 100%;
    padding: 5px;
    transition: 0.5s;
}

.header-metode:focus{
    transition: 0.5s;
    outline: none !important;
}

.card-text .collapsed{
    border-bottom: 2px solid rgb(200,200,200);
}

.card-text .not-collapsed{
    border-bottom: 2px solid #EC008C;
}

.card-metode{
    cursor:pointer;
}

.active-icon{
    color:#EC008C;
}

.passive-button{
    color: #EC008C;
    border: solid 1px  #EC008C;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    font-weight: 600;
    background-color: transparent;
    transition: 0.3s;
    margin-bottom: 10px;
}

.passive-button:hover, .passive-button:active{
    color: white;
    background-color:  #EC008C;
    transition: 0.3s;
}

.active-button{
    color: white;
    background-color: #EC008C;
    width: 100%;
    padding: 5px;
    border: solid 1px  #EC008C;
}

.active-button:hover{
    color: white;
    background-color: #EC008C;
}

.submit-button{
    color: #EC008C;
    border: solid 1px  #EC008C;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    background-color: transparent;
    transition: 0.3s;
}

.submit-button:hover, .submit-button:active{
    color: white;
    background-color: #EC008C;
    transition: 0.3s;
}

.disabled-button{
    color: rgba(236,0,140,0.5);
    border: solid 1px rgba(236,0,140,0.5);
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    background-color: transparent;
}

.disabled-button:hover{
    text-decoration:none;
}

.step-description{
    font-size: 16px;
}

.active-number{
    padding: 10px;
    max-width: 50px;
    border-radius: 50%;
    background-color: #EC008C;
    font-weight: 600;
    color: white;
    font-size: 18px;
}

.number{
    padding: 10px;
    max-width: 50px;
    border-radius: 50%;
    background-color: rgba(240,240,240,0.7);
    color: rgb(20,20,20);
    font-weight: 600;
    font-size: 18px;
}

.icon-copy{
    color: #EC008C;
}

.instruksi-pembayaran li{
    font-size: 14px;
}

.footer{
    bottom: 0 !important;
    margin-top: 120px;
    border-top:solid 1px rgb(200,200,200);
}

.icon-media{
    font-size:22px;
    color: rgb(40,40,40);
    transition: 0.3s;
}

.icon-media:hover{
    color: #EC008C;
    text-decoration: none;
    transition: 0.3s;
}

.voucher-input{
    font-size: 24px; 
    font-weight: 900;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: rgb(150,150,150);
}

@media (max-width: 576px){
    .step-description{
        font-size: 16px;
    }
}

</style>